
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { app } from '@config';
import { setTitle, handle429 } from '@util/util.ts';

import axios from 'axios';
import OverviewStroke from '@svg/OverviewStroke.vue';
import ClockSvg from '@svg/ClockMultiColor.vue';

/* -------------  ---------------------------
    Component
---------------------------------------- */

@Component({
    components: {
        OverviewStroke,
        ClockSvg,
    },
    data() {
        return {
            pageData: null,
        };
    },
    mounted() {
        let $this = this;
        axios
            .get(`${app.api.api}/page/${$this.$route.meta.id}`)
            .then(response => {
                $this.pageData = response.data;
                setTitle(response);
            })
            .catch(error => {
                console.log(`🔥${error}🔥`);
                handle429(this, error);
            });
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class ArticleCuratedOverview extends Vue {}
